import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
export default new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/contact",
      name: "Contact",
      component: () => import("./views/Contact.vue")
    },
    {
      path: "/apr",
      name: "APP Reviews",
      component: () => import("./views/apr.vue")
    },
    {
      path: "/mr",
      name: "Market Research",
      component: () => import("./views/mr.vue")
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login.vue")
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: {
        requiresAuth: true
      }
    }
  ]
});
