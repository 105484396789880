
import firebase from 'firebase';

// import initializeApp from "firebase/app";
// import auth from "firebase/auth";
// import "firebase/firestore";

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyDcZ0CnD5QfJOjxGWy4dom47fabyhKIEVM",
  authDomain: "ezcli-c19ea.firebaseapp.com",
  projectId: "ezcli-c19ea",
  storageBucket: "ezcli-c19ea.appspot.com",
  messagingSenderId: "68822911650",
  appId: "1:68822911650:web:2d9ba1e5bfa1cc4800e5fb",
  measurementId: "G-6K39TNK3W7",
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore()
const auth = firebase.auth();

// collection references
const appsCollection = db.collection("apps");
const usersCollection = db.collection("users");
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
  // db,
  auth,
  appsCollection,
  usersCollection,
  //   postsCollection,
  //   commentsCollection,
  //   likesCollection
};

