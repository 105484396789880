<template>
  <div id="footer-menu">
    <footer class="section bg-black">
      <!-- Triangles -->
      <!-- <div class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-left"></div>
    <div class="bg-triangle bg-triangle-light bg-triangle-bottom bg-triangle-right"></div> -->

      <!--Content -->
      <div class="container">
        <div class="row align-self-center">
          <div class="col-md-auto">
            <!-- Brand -->
            <p>
              <a href="#" class="footer-brand text-white">
                <img src="assets/img/logo_ezcli_white.svg" height="80px" />
              </a>
            </p>
          </div>
          <div class="col-md">
            <!-- Links -->
            <ul class="list-unstyled list-inline text-md-right">
              <!-- <li class="list-inline-item mr-2">
              <a href="#" class="text-white">
                Terms and conditions
              </a>
            </li> -->
              <li class="list-inline-item mr-2 align-top">
                services<br />
                <!-- <a href="sentiment_analysis.html" class="text-white">
                  Sentiment Analysis </a
                ><br /> -->
                <a href="apr" class="text-white"> App Reviews </a
                ><br /><a href="mr" class="text-white">Market Research</a
                ><br /><br />
                <!-- <a href="online_market_research.html" class="text-white">
                  Online Market Research
                </a> -->
              <!-- </li>
              <li class="list-inline-item align-top"> -->
                about ezcli.com<br />
                <!-- <a href="about.html" class="text-white"> About </a><br /> -->
                <a href="contact.html" class="text-white"> Contact </a><br />
                <!-- <a href="jobs.html" class="text-white">
                  Jobs
                </a> -->
                <!--<br />
              <a href="faq.html" class="text-white">
                FAQ
              </a> -->
              </li>
            </ul>
          </div>
        </div>
        <!-- / .row -->
        <div class="row">
          <div class="col-md" style="text-align: left;">
            <!-- Copyright -->
            <p class="text-white text-muted">
              <small
                ><a
                  class="text-primary"
                  href="
              mailto:contact@ezcli.com"
                  >contact@ezcli.com</a
                ><br />
                &copy; Copyright <span class="current-year"></span> ezcli
                ltd.<br />All rights reserved.
              </small>
            </p>
          </div>
          <div class="col-md">
            <!-- Social links -->
            <ul class="list-inline list-unstyled text-md-right">
              <!-- <li class="list-inline-item">
              <a href="#">
                <i class="fab fa-facebook"></i>
              </a>
            </li> -->
              <!-- <li class="list-inline-item ml-3">
              <a href="#">
                <i class="fab fa-github"></i>
              </a>
            </li>
            <li class="list-inline-item ml-3">
              <a href="#">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item ml-3">
              <a href="#">
                <i class="fab fa-instagram"></i>
              </a>
            </li> -->
            </ul>
          </div>
        </div>
        <!-- / .row -->
      </div>
      <!-- / .container -->
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
