<template>
  <v-app>
    <div id="app">
      <NavbarMenu></NavbarMenu>
      <div class="app-content"><v-main>
        <router-view /></v-main>
      </div>

      <FooterMenu></FooterMenu>
    </div>
  </v-app>
</template>

<script>
import NavbarMenu from "./components/navbar-menu/NavbarMenu.vue";
import FooterMenu from "./components/footer-menu/FooterMenu.vue";

export default {
  name: "App",
  components: { NavbarMenu, FooterMenu },
  data: () => ({}),
  computed: {
    // ...mapState(["userProfile"]),
    userProfile() {
      return this.$store.state.userProfile;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  top: 0;
}

.logo {
  width: 128px;
}

.app-content {
  /* margin: 64px 0 0 0; */
  /* box-sizing: border-box; */
  min-height: 100vh;
  display: flex;
}
</style>
