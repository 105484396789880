import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue'
import router from './router'
import vuetify from '@/plugins/vuetify'

import App from './App.vue'
import store from './store'

import { auth } from './firebase'

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

Vue.use(vuetify, {
  iconfont: 'md'
})

Vue.config.productionTip = false

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      vuetify,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})
