<template>
  <div id="navbar-menu">
    <v-app-bar color="white" flat app>
      <router-link :to="`/`" replace>
        <v-toolbar-title>
          <img
            alt="Vue logo"
            class="logo"
            src="../../assets/logo_ezcli.svg"
          /> </v-toolbar-title
      ></router-link>
      <v-spacer></v-spacer>
      <v-btn color="white" elevation="0" to="/"><v-icon>home</v-icon></v-btn>
      <v-menu offset-y content-class="elevation-0" class="navbar--dropdown">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" elevation="0" v-bind="attrs" v-on="on">
            <v-icon>dashboard</v-icon>
          </v-btn>
        </template>

        <v-card outlined>
          <v-list style="padding: 4px 0 0 0;">
            <router-link :to="`/apr`" replace>
              <v-card-title class="navbar--list">
                <img
                  alt="Vue logo"
                  class="logo--small"
                  src="../../assets/logo_wide_apr.svg"
                />
              </v-card-title>
              <!-- <v-list-item>
                <v-btn color="white" elevation="0">
                  APP Reviews</v-btn
                >
              </v-list-item> -->
            </router-link>
            <router-link :to="`/mr`" replace>
              <v-card-title class="navbar--list">
                <img
                  alt="Vue logo"
                  class="logo--small"
                  src="../../assets/logo_wide_mr.svg"
                />
              </v-card-title>
              <!-- <v-list-item>
                <v-btn color="white" elevation="0">
                  APP Reviews</v-btn
                >
              </v-list-item> -->
            </router-link>
            <v-card-title class="navbar--list">
              <img
                alt="Vue logo"
                class="logo--small-inactive"
                src="../../assets/logo_wide_sa.svg"
              />
            </v-card-title>
            <!-- <v-list-item>
                <v-btn color="white" elevation="0">
                  APP Reviews</v-btn
                >
              </v-list-item> -->
          </v-list></v-card
        >
      </v-menu>
      <v-btn color="white" elevation="0" to="/dev"><v-icon>code</v-icon></v-btn>
      <v-btn color="white" elevation="0" to="/contact"><v-icon>help</v-icon></v-btn>
      <template v-if="userProfile.name">
        <v-btn color="white" elevation="0" to="/Dashboard">Dashboard</v-btn>
        <v-menu offset-y content-class="elevation-0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="white" elevation="0" v-bind="attrs" v-on="on">
              <v-icon>face</v-icon> {{ userProfile.name }}
            </v-btn>
          </template>
          <v-card width="240" outlined>
            <v-list>
              <v-card-title>
                {{ userProfile.name }}
              </v-card-title>
              <v-list-item>
                <v-btn color="white" elevation="0" @click="logout()"
                  >Log Out</v-btn
                >
              </v-list-item>
            </v-list></v-card
          >
        </v-menu>
      </template>
      <template v-if="!userProfile.name">
        <v-btn color="white" elevation="0" to="/login"
          >
            <v-icon>face</v-icon>
          </v-btn>
      </template>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "NavbarMenu",
  data: () => ({}),
  computed: {
    userProfile() {
      return this.$store.state.userProfile;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    }
  }
};
</script>

<style lang="scss">
/* /<style lang="scss">
.card-img {
    &--dimmed{
      opacity: 33%;
    }
} */
.logo {
  &--small {
    height: 48px;
  }

  &--small-inactive {
    height: 48px;
    opacity: 12.5%;
    filter: grayscale(100%);
  }
}

.navbar {
  &--dropdown {
    width: 200px;
  }
  &--list {
    padding: 0 4px 4px 4px;
  }
}
</style>
